import { Swiper, SwiperSlide } from 'swiper/react';
import Project from "./Project";
import { Navigation } from 'swiper/modules';


import 'swiper/css';
import 'swiper/css/navigation';


function SimpleSlider() {
  const projects = [
        {
            link: 'https://github.com/MichalTalaga17/biblify',
            text: 'biblify is a simple organizer for biblical quotes, allowing users to browse, add, and manage quotes from the Bible.',
            title: 'biblify',
            badges: [
                { text: 'Swift', hover_color: 'blue' },
                { text: 'SwiftUI', hover_color: 'green' }
            ],
        },
        {
            link: 'https://github.com/MichalTalaga17/WeatherAPP',
            text: 'WeatherAPP is a weather application built with SwiftUI',
            title: 'Weather APP',
            badges: [
                { text: 'Swift', hover_color: 'blue' },
                { text: 'Figma', hover_color: 'red' }
            ],
        },
        {
          link: 'https://github.com/MichalTalaga17/MusicControlApp',
          text: 'Allows the user to control music playback through an intuitive interface',
          title: 'biblify',
          badges: [
              { text: 'Swift', hover_color: 'blue' },
              { text: 'SwiftUI', hover_color: 'green' }
          ],
      },
      {
          link: 'https://github.com/MichalTalaga17/redesign_ios',
          text: 'Figma redesign of iOS default apps',
          title: 'iOS redesign',
          badges: [
              { text: 'Figma', hover_color: 'red' }
          ],
      }
    ];
  return (
    <Swiper
      modules={[Navigation]}
      spaceBetween={20}
      slidesPerView={1}
      navigation
      loop
      breakpoints={{
        200: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
        1400: {
          slidesPerView: 4,
          spaceBetween: 50,
        },
      }}
    >
      {projects.map((project, index) => (
        <SwiperSlide><Project {...project} key={index} /></SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SimpleSlider;