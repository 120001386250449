import React from "react";
import SingleTechnology from "./SingleTechnology";

function Languages() {
    return (
        <div className="technology">
            <div className="resume-partname small-title title">
                Languages
            </div>
                <SingleTechnology text={"HTML"} stars={5} />
                <SingleTechnology text={"CSS/SCSS"} stars={2} />
                <SingleTechnology text={"JavaScript"} stars={4} />
                <SingleTechnology text={"React"} stars={2} />
                <SingleTechnology text={"SQL"} stars={4} />
                <SingleTechnology text={"Swift"} stars={3} />
                <SingleTechnology text={"SwiftUI"} stars={3} />
        </div>
    );
            }
export default Languages;