import React from "react";
import SingleWork from "./SingleWork";


function Experience() {
    const works = [
        {
            link: "https://webcrafters.studio/",
            title: "WebCrafters Studio 2023",
            text: "A half year-long internship focused on web development and UI designing"
        },
        {
            link: "https://fitech.pl/",
            title: "Fitech, 2022",
            text: "A month-long internship during which I learned about the methodologies of managing a modern company. "
        },
        {
            link: "http://zsgsucha.pl/",
            title: "Erasmus+, 2022",
            text: "I gave lectures on modern web technologies and tools that allow you to create interactive websites in Macedonia, Turkey and Poland"
        },
        {
            link: "http://zsgsucha.pl/",
            title: "ZS Goetel, 2020 - present",
            text: "I designed print materials such as flyers, banners or posters. I also created infographics, e-book covers and e-newsletters."
        }
    ];

    return (
        <div className="experience">
            <div className="resume-partname small-title title">
                Experience
            </div>
            {works.map((work, index) => (
                <SingleWork
                    key={index}
                    link={work.link}
                    title={work.title}
                    year={work.year}
                    text={work.text}
                />
            ))}
        </div>
    );
            }
export default Experience;