import React from 'react';
import Button from './Button';

function SocialButtonRow() {
  return (
    <React.StrictMode>
      <div className="social-buttonspace row gap-25 sm-none">
        <Button link="https://github.com/MichalTalaga17" text="Github" hover_color="yellow" target={"_blank"} />
        <Button link="https://dribbble.com/talagamichal" text="Dribble" hover_color="pink" target={"_blank"} />
        <Button link="https://www.behance.net/michatalaga" text="Behance" hover_color="blue" target={"_blank"}/>
      </div>
    </React.StrictMode>
  );
}

export default SocialButtonRow;
